<template>
  <div id="app" :class="classValidate"></div>
</template>

<script>

export default {
  name: 'App',
  data() {
    return {
      is_active: false,
    }
  },
  computed: {
    classValidate() {
      return this.is_active ? 'img-back' : 'img-back-2'
    }
  },
  methods: {
    async getEmployeeActive() {
      const axios = require('axios');
      const urlSearchParams = new URLSearchParams(window.location.search);
      const params = Object.fromEntries(urlSearchParams.entries());
      const data = JSON.stringify({
        "document": params.cedula,
        "type_document": params.type_document,
        "company": params.company
      });
      const config = {
        method: 'post',
        url: 'https://importadordbrrhh.smartquick.com.co/employee-information/validate',
        headers: { 
          'Authorization': 'Token bff60d2cb5cae9ca330d1809136158f62eb46110', 
          'Content-Type': 'application/json'
        },
        data : data
      };
      return new Promise((resolve) => {
        axios(config)
        .then(function (response) {
          resolve(response.data)
        })
        .catch(function (error) {
          console.log(error);
        });
      })
    }
  },
  async mounted() {
    this.is_active = await this.getEmployeeActive()
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
.img-back {
  background-image: url('./assets/pantalla1verde.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 100%;
  width: 100%;
  height: auto;
  position: fixed;
  top: -60px;
  left: 0;
}
.img-back-2 {
  background-image: url('./assets/pantalla2roja.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 100%;
  width: 100%;
  height: auto;
  position: fixed;
  top: -60px;
  left: 0;
}
</style>
